myApp.directive('fileread', [function(){
  return {
    scope : { fileread : "=" },
    link : function(scope, element, attributes){
      element.bind("change", function(changeEvent){
        var reader = new FileReader();
        //debugger;
        reader.onload = function(loadEvent){
            scope.$apply(function(){
              scope.fileread = loadEvent.target.result;
            });
        }
        reader.readAsDataURL(changeEvent.target.files[0]);

      });
    }
  }
}]);

myApp.directive('multiplefileread',[function(){
  return {
    scope : { multiplefileread : '=' },
    link : function(scope,element,attributes){
      scope.multiplefileread = [];
      element.bind("change", function(changeEvent){
        //debugger;
        /*var reader = new FileReader();
        reader.onload = function(loadEvent){
          scope.multipleFileread.push(loadEvent.target.result );
        }*/
        for(var i = 0; i < changeEvent.target.files.length ; i++ ){
          var file = changeEvent.target.files[i];
          var reader = new FileReader();
          reader.onload = (function(file){
            return function(e){
              scope.$apply(function(){
                  scope.multiplefileread.push( e.target.result );
              });
            }
          })(file);
          reader.readAsDataURL(file);
        }//end
      });
    }
  }
}]);
